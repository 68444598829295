import React from 'react';
import { useRecoilState } from 'recoil';
import { collapsedState } from '../../utils/recoil-atoms';
import { Link } from 'gatsby';

const Navbar = () => {
  const [collapsed, setCollapsed] = useRecoilState(collapsedState);

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  React.useEffect(() => {
    let nav = document.getElementById('navbar');
    // let logo= document.getElementById("logo");
    document.addEventListener('scroll', () => {
      if (window.scrollY > 170) {
        nav.classList.add('is-sticky');
      } else {
        nav.classList.remove('is-sticky');
      }
    });
    window.scrollTo(0, 0);
  });

  const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
  const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

  return (
    <React.Fragment>
      <div id='navbar' className='navbar-area'>
        <div className='tarn-nav'>
          <div className='container-fluid'>
            <nav className='navbar navbar-expand-lg navbar-light'>
              <Link to='/' onClick={() => setCollapsed(true)} className='navbar-brand'></Link>

              <button
                onClick={toggleNavbar}
                className={classTwo}
                type='button'
                data-toggle='collapse'
                data-target='#navbarSupportedContent'
                aria-controls='navbarSupportedContent'
                aria-expanded='false'
                aria-label='Toggle navigation'
              >
                <span className='icon-bar top-bar'></span>
                <span className='icon-bar middle-bar'></span>
                <span className='icon-bar bottom-bar'></span>
              </button>

              <div className={classOne} id='navbarSupportedContent'>
                <ul className='navbar-nav'>
                  <li className='nav-item'>
                    <Link to='/' activeClassName='active' className='nav-link'>
                      Solutions
                    </Link>
                  </li>

                  <li className='nav-item'>
                    <Link to='/services' activeClassName='active' className='nav-link'>
                      Services
                    </Link>
                    <ul className='dropdown-menu'>
                      <li className='nav-item'>
                        <Link to='/iso27001' activeClassName='active' className='nav-link'>
                          ISO27001 Information Security Management System (ISMS)
                        </Link>
                      </li>
                      <li className='nav-item'>
                        <Link to='/SSAE18' activeClassName='active' className='nav-link'>
                          SSAE18 (SOC 2)
                        </Link>
                      </li>

                      <li className='nav-item'>
                        <Link to='/cmmc' activeClassName='active' className='nav-link'>
                          Cybersecurity Maturity Model Certification (CMMC)
                        </Link>
                      </li>

                      <li className='nav-item'>
                        <Link to='/services' activeClassName='active' className='nav-link btn-more'>
                          See More Services
                        </Link>
                      </li>
                    </ul>
                  </li>

                  <li className='nav-item'>
                    <Link to='/compliance' activeClassName='active' className='nav-link'>
                      Compliance
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link to='/company' activeClassName='active' className='nav-link'>
                      Company
                    </Link>
                  </li>

                  <li className='nav-item'>
                    <Link to='/contact' activeClassName='active' className='nav-link'>
                      Contact
                    </Link>
                  </li>

                  <li className='nav-item'>
                    <Link to='https://content.ekkosecurity.com' activeClassName='active' className='nav-link'>
                      Resources
                    </Link>
                  </li>
                </ul>

                <div className='others-option d-flex align-items-center'>
                  <div className='option-item'>
                    <Link
                      to='/contact'
                      activeClassName='active'
                      onClick={() => setCollapsed(true)}
                      className='default-btn'
                    >
                      <i className='flaticon-right'></i> Request Demo<span></span>
                    </Link>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Navbar;
