import React from 'react';
import { Link } from 'gatsby';
import logo from '../../assets/images/logos/logo-dark.png';
import footerMap from '../../assets/images/footer/footer-map.png';

import ReactWOW from 'react-wow';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className='footer-area bg-color'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-4 col-sm-6'>
            <div className='single-footer-widget'>
              <a href='/' className='logo'>
                <img src={logo} style={{ maxWidth: '140px' }} alt='logo' />
              </a>
              <p>Palo Alto, CA</p>

              <a href='mailto:ekko-info@ekkoconsulting.com '>ekko-info@ekkoconsulting.com</a>
            </div>
          </div>

          <div className='col-lg-2 col-sm-6'>
            <div className='single-footer-widget pl-5'>
              <h3>Explore</h3>

              <ul className='footer-links-list'>
                <li>
                  <Link to='/'>Solutions</Link>
                </li>
                <li>
                  <Link to='/services'>Services</Link>
                </li>
                <li>
                  <Link to='/compliance'>Compliance</Link>
                </li>
                <li>
                  <Link to='/privacy-policy'>Privacy Policy</Link>
                </li>
                <li>
                  <Link to='/contact'>Contact</Link>
                </li>
                <li>
                  <Link to='https://content.ekkosecurity.com'>Resources</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className='col-lg-4 col-sm-6'>
            <div className='single-footer-widget'>
              <h2>Ready to Get Started?</h2>
              <ReactWOW delay='.1s' animation='fadeInRight'>
                <div className='btn-box'>
                  <Link to='/contact' className='default-btn'>
                    Request a Demo
                    <i className='flaticon-right'></i>
                    <span></span>
                  </Link>
                </div>
              </ReactWOW>
            </div>
          </div>
        </div>

        <div className='footer-bottom-area'>
          <div className='row align-items-center'>
            <div className='col-lg-6 col-md-6'>
              <p>Copyright @{currentYear} by EKKO Consulting, Inc.</p>
            </div>

            <div className='col-lg-6 col-md-6'>
              {/* <ul>
                                <li>
                                    <Link to="/privacy-policy">
                                        Privacy Policy
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/terms-of-service">
                                        Terms & Conditions
                                    </Link>
                                </li>
                            </ul> */}
            </div>
          </div>
        </div>
      </div>

      <div className='footer-map'>
        <img src={footerMap} alt='footer-logo' />
      </div>
    </footer>
  );
};

export default Footer;
